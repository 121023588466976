export * from "./layout";
export const logoSm = "/logo/logo-sm.png";
export const logoDark = "/logo/logo-dark.png";
export const logoLight = "/logo/logo-light.png";

export const STATUS_CONSTANTS = {
  SUGGESTED: "SUGGESTED",
  APPROVED: "APPROVED",
  APPLIED: "APPLIED",
  REJECTED: "REJECTED",
};
