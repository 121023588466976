//@ts-nocheck
import update from "immutability-helper";

import {
  GET_CANDIDATES,
  GET_CANDIDATES_PAGINATED,
  GET_JOBS,
  GET_JOBS_PAGINATED,
} from "../../graphqlModule/queries";
import { ADD_JOB } from "../../graphqlModule/mutations";

import { setLoading } from "../common/module";
import toast from "react-hot-toast";
import _ from "lodash";
export const NAME = "EMPLOYEE";

export const SET_JOBS = `SET_JOBS ${NAME}`;
export const SET_CANDIDATES = `SET_CANDIDATES ${NAME}`;

export const setJobs = data => {
  return {
    type: SET_JOBS,
    payload: data,
  };
};

export const setCandidates = data => {
  return {
    type: SET_CANDIDATES,
    payload: data,
  };
};

// THUNKS
export const getJobs = () => {
  return async (dispatch, _, { client }) => {
    const { data } = await client.query({
      query: GET_JOBS,
    });
    dispatch({
      type: SET_JOBS,
      payload: data.jobs,
    });
  };
};

export const getCandidates = () => {
  return async (dispatch, state, { client }) => {
    dispatch(setLoading(true));
    try {
      const { data, error } = await client.query({
        query: GET_CANDIDATES,
      });
      if (error) throw error;

      const candidates = _.get(data, "employee.allCandidates", []);

      dispatch({
        type: SET_CANDIDATES,
        payload: candidates,
      });
    } catch (e) {
      console.error(e);
      toast.error("Error fetching candidates");
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const getEmployees = () => {
  return async (dispatch, state, { client }) => {
    dispatch(setLoading(true));
    try {
      const { data, error } = await client.query({
        query: GET_CANDIDATES,
      });
      if (error) throw error;

      const candidates = _.get(data, "employee.allCandidates", []);

      dispatch({
        type: SET_CANDIDATES,
        payload: candidates,
      });
    } catch (e) {
      console.error(e);
      toast.error("Error fetching candidates");
    } finally {
      dispatch(setLoading(false));
    }
  };
};

// Reducer
const REDUCER_HANDLERS = {
  [SET_JOBS]: (state, action) => {
    return update(state, {
      jobs: { $set: action.payload },
    });
  },
  [SET_CANDIDATES]: (state, action) => {
    return update(state, {
      candidates: { $set: action.payload },
    });
  },
};

const initialState = {
  jobs: [],
  candidates: [],
};

export default function reducer(state = initialState, action) {
  const handler = REDUCER_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
