//@ts-nocheck
import update from "immutability-helper";
import { GET_LOCATIONS, GET_TODOS } from "../../graphqlModule/queries";
import { ADD_TODO } from "../../graphqlModule/mutations";
import _map from "lodash/map";
// Constants
export const NAME = "CANDIDATE";

export const SET_PROGRESS_LOADING_PERCENTAGE = `SET_PROGRESS_LOADING_PERCENTAGE ${NAME}`;
export const GET_LOCATIONS_DATA = `GET_LOCATIONS_DATA ${NAME}`;
export const GET_TODOS_DATA = `GET_TODOS_DATA ${NAME}`;
export const UPDATE_TODOS_DATA = `UPDATE_TODOS_DATA ${NAME}`;

//actions
export const setProgressLoadingPrecentage = data => {
  return {
    type: SET_PROGRESS_LOADING_PERCENTAGE,
    payload: data,
  };
};

// THUNKS
export const updateProgressLoadingState = uploadLoadingPercentage => {
  return dispatch => {
    const newPercentage = uploadLoadingPercentage + 1;
    dispatch(setProgressLoadingPrecentage(newPercentage));
  };
};

export const getLocationsData = () => {
  return async (dispatch, _, { client }) => {
    const { data } = await client.query({
      query: GET_LOCATIONS,
    });
    dispatch({
      type: GET_LOCATIONS_DATA,
      payload: data.locations,
    });
  };
};

export const getTodosData = () => {
  return async (dispatch, _, { client }) => {
    const { data } = await client.query({
      query: GET_TODOS,
    });
    const todos = _map(data.getTodos, todo => {
      return {
        id: todo.id,
        name: todo.title,
      };
    });
    dispatch({
      type: GET_TODOS_DATA,
      payload: todos,
    });
  };
};

export const addTodo = ({ title }) => {
  return async (dispatch, _, { client }) => {
    const { data } = await client.mutate({
      mutation: ADD_TODO,
      variables: {
        title,
      },
    });
    const addedTodo = {
      id: data.addTodo.id,
      name: data.addTodo.title,
    };
    dispatch({
      type: UPDATE_TODOS_DATA,
      payload: addedTodo,
    });
  };
};

// Reducers
const REDUCER_HANDLERS = {
  [SET_PROGRESS_LOADING_PERCENTAGE]: (state, action) => {
    return update(state, {
      progressLoaderPercentage: { $set: action.payload },
    });
  },
  [GET_LOCATIONS_DATA]: (state, action) => {
    return update(state, {
      locations: { $set: action.payload },
    });
  },
  [GET_TODOS_DATA]: (state, action) => {
    return update(state, {
      todos: { $set: action.payload },
    });
  },
  [UPDATE_TODOS_DATA]: (state, action) => {
    return update(state, {
      todos: { $push: [action.payload] },
    });
  },
};

// Export Reducer
const initialState = {
  progressLoaderPercentage: 0,
  locations: [],
  todos: [],
};

export default function myReducer(state = initialState, action) {
  const handler = REDUCER_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
