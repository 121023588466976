import React from "react";
import { Route, Navigate, RouteProps } from "react-router-dom";

// components
import PrivateRoute from "./PrivateRoute";
import Root from "./Root";

// lazy load all the views

// auth
const Login = React.lazy(() => import("../pages/auth/Login"));
const Logout = React.lazy(() => import("../pages/auth/Logout"));
const Confirm = React.lazy(() => import("../pages/auth/Confirm"));
const Otp = React.lazy(() => import("../pages/auth/Otp"));
const ForgetPassword = React.lazy(() => import("../pages/auth/ForgetPassword"));
const Register = React.lazy(() => import("../pages/auth/Register"));
const SSO = React.lazy(() => import("../pages/auth/Sso"));
const ThirdPartySso = React.lazy(() => import("../pages/auth/ThirdPartySso"));

// landing
const Landing = React.lazy(() => import("../pages/landing/"));

const JobApplicationDashboard = React.lazy(
  () => import("../pages/JobApplicationDashboard")
);

const CandidateProfile = React.lazy(
  () => import("../pages/Candidate/Profile/")
);

const ChangePlan = React.lazy(() => import("../pages/Candidate/ChangePlan/"));
const ReferFriend = React.lazy(() => import("../pages/Candidate/ReferFriend/"));

// const CandidateOnboard = React.lazy(() => import("../pages/CandidateOnboard"));
const Error404Alt = React.lazy(() => import("../pages/error/Error404Alt"));

//employee routes
const JobApplicants = React.lazy(
  () => import("../pages/Employee/JobApplicants")
);
const MyWork = React.lazy(() => import("../pages/Employee/MyWork/Customers"));
const AllJobs = React.lazy(() => import("../pages/Employee/AllJobs"));
const JobDetails = React.lazy(() => import("../pages/Employee/JobDetails"));
const Candidate = React.lazy(() => import("../pages/Employee/Candidate"));
const EmployeeList = React.lazy(() => import("../pages/Admin/EmployeeList"));

export interface RoutesProps {
  path: RouteProps["path"];
  name?: string;
  element?: RouteProps["element"];
  route?: any;
  exact?: boolean;
  icon?: string;
  header?: string;
  roles?: string[];
  children?: RoutesProps[];
}

// root routes
const rootRoute: RoutesProps = {
  path: "/",
  exact: true,
  name: "Root",
  element: <Root />,
  route: Route,
};

const JobApplicationDashboardRoute = {
  path: "/apps/job-application-dashboard",
  name: "Candidate Onboard",
  route: PrivateRoute,
  roles: ["CANDIDATE"],
  icon: "folder-plus",
  element: <JobApplicationDashboard />,
};

const OurPlansDashboardRoute = {
  path: "/plans",
  name: "Our Plans",
  route: PrivateRoute,
  roles: ["Admin", "CANDIDATE"],
  icon: "aperture",
  element: <ChangePlan />,
};

const ReferralPageRoute = {
  path: "/refer",
  name: "Referral",
  route: PrivateRoute,
  roles: ["Admin", "CANDIDATE"],
  icon: "users",
  element: <ReferFriend />,
};

const CandidateProfileRoute = {
  path: "/profile",
  name: "Profile",
  element: <CandidateProfile />,
  route: PrivateRoute,
  roles: ["Admin", "EMPLOYEE", "CANDIDATE"],
  icon: "folder-plus",
};

const ssoRoute = {
  path: "/sso",
  name: "SSO",
  route: PrivateRoute,
  roles: ["ADMIN", "EMPLOYEE", "CANDIDATE"],
  element: <SSO />,
};

const ThirdPartySsoRoute = {
  path: "/sso-2",
  name: "SSO-2",
  route: PrivateRoute,
  element: <ThirdPartySso />,
};

// auth
const authRoutes: RoutesProps[] = [
  {
    path: "/auth/login",
    name: "Login",
    element: <Login />,
    route: Route,
  },
  {
    path: "/auth/register",
    name: "Register",
    element: <Register />,
    route: Route,
  },
  {
    path: "/auth/confirm",
    name: "Confirm",
    element: <Confirm />,
    route: Route,
  },
  {
    path: "/auth/otp-signup",
    name: "otp-signup",
    element: <Otp />,
    route: Route,
  },
  {
    path: "/auth/forget-password",
    name: "Forget Password",
    element: <ForgetPassword />,
    route: Route,
  },
  {
    path: "/auth/logout",
    name: "Logout",
    element: <Logout />,
    route: Route,
  },
];

// public routes
const otherPublicRoutes = [
  {
    path: "/landing",
    name: "landing",
    element: <Landing />,
    route: Route,
  },
  // {
  //   path: "/404",
  //   name: "Error - 404-alt",
  //   element: <Error404Alt />,
  //   route: PrivateRoute,
  // },
];

// flatten the list of all nested routes
const flattenRoutes = (routes: RoutesProps[]) => {
  let flatRoutes: RoutesProps[] = [];

  routes = routes || [];
  routes.forEach((item: RoutesProps) => {
    flatRoutes.push(item);

    if (typeof item.children !== "undefined") {
      flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
    }
  });
  return flatRoutes;
};

const EmployeeRoutes = [
  {
    path: "/employee/job-applicants",
    name: "Job Applicants",
    route: PrivateRoute,
    roles: ["EMPLOYEE"],
    icon: "users",
    element: <JobApplicants />,
  },
  {
    path: "/employee/my-work",
    name: "My work",
    route: PrivateRoute,
    roles: ["EMPLOYEE"],
    icon: "briefcase",
    element: <AllJobs />,
  },
  {
    path: "/employee/candiate/:id/view",
    name: "Job Details",
    element: <Candidate isEdit={false} />,
    route: PrivateRoute,
    roles: ["ADMIN", "EMPLOYEE"],
  },
  {
    path: "/employee/job/:id/view",
    name: "Job Details",
    element: <JobDetails isEdit={false} />,
    route: PrivateRoute,
    roles: ["ADMIN", "EMPLOYEE"],
  },
  {
    path: "/employee/job/:id/edit",
    name: "Job Details",
    element: <JobDetails isEdit={true} />,
    route: PrivateRoute,
    roles: ["EMPLOYEE"],
  },
];

const AdminRoutes = [
  {
    path: "/admin/candidates",
    name: "Candidates",
    route: PrivateRoute,
    roles: ["ADMIN"],
    icon: "users",
    element: <JobApplicants isAdmin={true} />,
  },
  {
    path: "/admin/all-jobs",
    name: "All jobs",
    route: PrivateRoute,
    roles: ["ADMIN"],
    icon: "briefcase",
    element: <AllJobs isAdmin={true} />,
  },
  {
    path: "/admin/employees",
    name: "Employees",
    route: PrivateRoute,
    roles: ["ADMIN"],
    icon: "users",
    element: <EmployeeList />,
  },
];

// All routes
const authProtectedRoutes = [
  rootRoute,
  JobApplicationDashboardRoute,
  CandidateProfileRoute,
  OurPlansDashboardRoute,
  ReferralPageRoute,
  ...EmployeeRoutes,
  ...AdminRoutes,
];
const publicRoutes = [
  ...authRoutes,
  ...otherPublicRoutes,
  ssoRoute,
  ThirdPartySsoRoute,
];

const authProtectedFlattenRoutes = flattenRoutes([...authProtectedRoutes]);
const publicProtectedFlattenRoutes = flattenRoutes([...publicRoutes]);
export {
  publicRoutes,
  authProtectedRoutes,
  authProtectedFlattenRoutes,
  publicProtectedFlattenRoutes,
};
