export const getName = ({ firstName = "", middleName = "", lastName = "" }) => {
  let name;

  if (firstName) {
    name = firstName;
  }

  if (middleName) {
    name = name ? `${name} ${middleName}` : middleName;
  }

  if (lastName) {
    name = name ? `${name} ${lastName}` : lastName;
  }

  return name;
};
