import _ from "lodash";
import moment from "moment";

function isNumber(value: any) {
  // Attempt to convert value to a number
  const number = _.toNumber(value);
  // Check if the converted number is finite, which also implies it's a number
  return _.isFinite(number);
}

function convertTimeStampToDate(timestamp: number | string) {
  if (typeof timestamp === "string" && isNumber(timestamp)) {
    timestamp = parseInt(timestamp);
  }
  var months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  var date = moment(timestamp).toDate();
  // var date = new Date(timestamp);

  var day = date.getDate();
  var month = months[date.getMonth()];
  var year = date.getFullYear();

  var suffixes = ["th", "st", "nd", "rd"];
  var relevantDigits = day < 30 ? day % 20 : day % 30;
  var suffix = relevantDigits <= 3 ? suffixes[relevantDigits] : suffixes[0];

  return `${day}${suffix} ${month}, ${year}`;
}

export { convertTimeStampToDate };
