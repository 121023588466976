import { combineReducers } from "redux";

import Common from "./common/module";
import Auth from "./auth/module";
import Layout from "./layout/reducers";
import Candidate from "./app/module";
import Employee from "./app/employee";

export default combineReducers({
  Common,
  Auth,
  Layout,
  Candidate,
  Employee,
});
