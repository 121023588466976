import _ from "lodash";
import { Session } from "@supabase/supabase-js";
import getJwt from "helpers/jwt";

const getUserObjectFromSession = (session: Session) => {
  if (_.isEmpty(session)) return {};
  const userRole = _.get(session, "user.user_metadata.custom_role", "");
  return {
    id: _.get(session, "user.id", ""),
    name: _.get(session, "user.name", ""),
    email: _.get(session, "user.email", ""),
    role: _.get(session, "user.role", ""),
    app_metadata: _.get(session, "user.app_metadata", {}),
    user_metadata: _.get(session, "user.user_metadata", {}),
    jwt: _.get(session, "access_token", ""),
    jwtExpiresAt: _.get(session, "expires_at", ""),
    userRole: userRole,
    isCandidate: _.toUpper(userRole) === "CANDIDATE",
  };
};

const isUserLoggedIn = () => {
  return !_.isEmpty(getJwt());
};

export { getUserObjectFromSession, isUserLoggedIn };
