import React from "react";

const FullScreenLoader = () => {
  return (
    <div
      className="background"
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: 1100,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0,0,0,0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="spinner-border text-light" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
};

export default FullScreenLoader;
