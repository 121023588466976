import React from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import _ from "lodash";
// strore
import { RootState } from "../redux/store";

// All layouts containers
import DefaultLayout from "../layouts/Default";
import VerticalLayout from "../layouts/Vertical";

import {
  authProtectedFlattenRoutes,
  publicProtectedFlattenRoutes,
} from "./index";

import FullScreenLoader from "../components/FullScreenLoader";
import { Toaster } from "react-hot-toast";
const Error404Alt = React.lazy(() => import("../pages/error/Error404Alt"));

interface IRoutesProps {}

const AllRoutes = (props: IRoutesProps) => {
  const { layout, user, loading, userRole } = useSelector(
    (state: RootState) => ({
      layout: state.Layout,
      user: state.Auth?.user,
      userRole: state.Auth?.user?.userRole,
      loading: state.Common.loading,
    })
  );

  const userLoggedIn = !_.isEmpty(user?.jwt);

  let Layout = VerticalLayout;

  const location = useLocation();

  return (
    <React.Fragment>
      {loading && <FullScreenLoader />}
      <Routes>
        <Route>
          {publicProtectedFlattenRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={
                <DefaultLayout {...props} layout={layout}>
                  {route.element}
                </DefaultLayout>
              }
              key={idx}
            />
          ))}
        </Route>

        <Route>
          {authProtectedFlattenRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={
                userLoggedIn === false ? (
                  <Navigate
                    to={{
                      pathname: "/auth/login",
                      // hash:route.path,
                      search: `next=${location.pathname}${location.search}`,
                    }}
                  />
                ) : (
                  <Layout {...props}>
                    {route.roles && route?.roles?.indexOf(userRole) === -1 ? (
                      <Error404Alt />
                    ) : (
                      route.element
                    )}
                  </Layout>
                )
              }
              key={idx}
            />
          ))}
        </Route>
      </Routes>
      <Toaster />
    </React.Fragment>
  );
};

export default AllRoutes;
