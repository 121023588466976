import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../redux/store";
import _ from "lodash";
import { setUsePlanInState } from "src/redux/auth/module";
import FullScreenLoader from "components/FullScreenLoader";

const getRootUrl = (
  userRole: string,
  appProvider: string,
  currentPlan?: string
) => {
  if (userRole === "ADMIN") {
    return "/admin/employees";
  } else if (userRole === "EMPLOYEE") {
    return "/employee/job-applicants";
  } else if (userRole === "CANDIDATE") {
    if (currentPlan === "NO_PLAN") {
      return "/plans";
    }

    return "/apps/job-application-dashboard";
  } else if (appProvider !== "email") {
    return "/sso-2";
  }
  return "";
};

const Root = () => {
  const dispatch = useDispatch();
  const { user, userRole, isCandidate, currentPlan } = useSelector(
    (state: RootState) => ({
      user: state.Auth?.user,
      userRole: state.Auth?.user?.userRole,
      isCandidate: state.Auth?.user?.isCandidate,
      currentPlan: state.Auth?.currentPlan,
    })
  );

  useEffect(() => {
    if (userRole == "CANDIDATE" && isCandidate && _.isEmpty(currentPlan)) {
      dispatch(setUsePlanInState());
    }
  }, [userRole, currentPlan, isCandidate]);

  const userLoggedIn = !_.isEmpty(user?.jwt);

  const appProvider = _.get(user, "app_metadata.provider", "email");

  if (userLoggedIn === false) {
    return (
      <Navigate
        to={{
          pathname: "/auth/login",
        }}
      />
    );
  }

  const checkLoadingCondition =
    userRole == "CANDIDATE" && isCandidate && _.isEmpty(currentPlan);
  if (checkLoadingCondition) {
    return <FullScreenLoader />;
  }
  const url = getRootUrl(userRole, appProvider, currentPlan);
  if (url) {
    return <Navigate to={url} />;
  }

  return <p></p>;
};

export default Root;
