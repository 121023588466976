//@ts-nocheck
import update from "immutability-helper";
import supabase from "supabase";

import { getUserObjectFromSession } from "utils";
import { GET_CANDIDATE_DETAILS } from "query";
import { CREATE_CANDIDATE, CREATE_CANDIDATE_OAUTH } from "mutation";
import graphClient from "../../graphqlModule/client";
import _ from "lodash";
import toast from "react-hot-toast";

// Constants
export const NAME = "AUTH";

export const LOGOUT_USER = `LOGOUT_USER ${NAME}`;

export const RESET = `RESET ${NAME}`;

export const FORGOT_PASSWORD = `FORGOT_PASSWORD ${NAME}`;

export const SET_AUTH_LOADING = `SET_AUTH_LOADING ${NAME}`;
export const SET_AUTH_ERROR = `SET_AUTH_ERROR ${NAME}`;
export const SIGNUP_USER = `SIGNUP_USER ${NAME}`;
export const SET_USER = `SET_USER ${NAME}`;
export const CLEAR_USER = `CLEAR_USER ${NAME}`;
export const SET_PLAN = `SET_PLAN ${NAME}`;
export const RESET_PLAN = `RESET_PLAN ${NAME}`;

//actions
export const setAuthLoading = data => {
  return {
    type: SET_AUTH_LOADING,
    payload: data,
  };
};

export const setAuthError = data => {
  return {
    type: SET_AUTH_ERROR,
    payload: data,
  };
};

export const signupUser = data => {
  return {
    type: SIGNUP_USER,
    payload: data,
  };
};

export const setUserDetails = data => {
  return {
    type: SET_USER,
    payload: data,
  };
};

export const clearUserDetails = () => {
  return {
    type: CLEAR_USER,
  };
};

export const setPlan = data => {
  return {
    type: SET_PLAN,
    payload: data,
  };
};

export const resetPlan = () => {
  return {
    type: RESET_PLAN,
  };
};

export const logoutUser = () => {
  return {
    type: LOGOUT_USER,
  };
};

export const reset = () => {
  return {
    type: RESET,
  };
};

export const forgotPassword = data => {
  return {
    type: FORGOT_PASSWORD,
    payload: data,
  };
};

// THUNKS
export const loginUserThunk = data => {
  return async dispatch => {
    dispatch(setAuthLoading(true));

    const { email, password } = data;
    //login using supabase
    const { error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });

    if (error) {
      dispatch(setAuthError(_.get(error, "message", "Something went wrong")));
    }
    dispatch(setAuthLoading(false));
  };
};

export const logoutUserThunk = () => {
  return async dispatch => {
    await supabase.auth.signOut();
    dispatch(logoutUser());
  };
};

export const signupUserThunk = ({
  email,
  password,
  firstName,
  middleName,
  lastName,
  gender,
}) => {
  return async dispatch => {
    try {
      //start loading
      dispatch(setAuthLoading(true));

      // Create an instance of URLSearchParams
      const params = new URLSearchParams({
        firstName,
        middleName,
        lastName,
        gender,
      });

      const dataPayload = {
        custom_role: "CANDIDATE",
        first_name: firstName,
        middle_name: middleName,
        last_name: lastName,
        gender,
      };
      // console.log("LOG: dataPayload", dataPayload);

      //signup using supabase
      const { error, data } = await supabase.auth.signUp({
        email,
        password,
        options: {
          data: dataPayload,
          emailRedirectTo: `${window.location.origin}/sso`,
          // emailRedirectTo: `${
          //   window.location.origin
          // }/sso/?${params.toString()}`,
        },
      });

      const { session, user } = data;

      if (error) {
        throw error;
      } else if (user && user?.identities && user?.identities?.length === 0) {
        //is user already exists
        throw new Error("User with email already exists");
      } else if (_.isEmpty(session)) {
        dispatch(signupUser(email));
      }

      return true;
    } catch (e) {
      console.log("LOG: signup error:-", e);
      let error = _.get(e, "message", "Something went wrong");
      if (error === "Failed to fetch") {
        error = "Netwrok Error. Please check your internet connection";
      }
      dispatch(setAuthError(error));
      dispatch(setAuthLoading(false));
      return false;
    } finally {
      dispatch(setAuthLoading(false));
    }
  };
};

export const confirmOtp = ({ otp }) => {
  return async (dispatch, getState) => {
    try {
      const state = getState();

      const email = state?.Auth?.userSignedUpEmail;
      if (_.isEmpty(email)) {
        throw new Error("Email is required");
      }
      dispatch(setAuthLoading(true));

      const { error, data } = await supabase.auth.verifyOtp({
        email,
        token: `${otp}`,
        type: "email",
      });

      const { session, user } = data;

      if (error) {
        throw error;
      }

      return true;
    } catch (e) {
      console.log("LOG: confirmOtp error:-", e);
      let error = _.get(e, "message", "Something went wrong");
      if (error === "Failed to fetch") {
        error = "Netwrok Error. Please check your internet connection";
      }
      dispatch(setAuthError(error));
      dispatch(setAuthLoading(false));
      return false;
    } finally {
      dispatch(setAuthLoading(false));
    }
  };
};

export const resendOtp = () => {
  return async (dispatch, getState) => {
    try {
      const state = getState();

      const email = state?.Auth?.userSignedUpEmail;
      if (_.isEmpty(email)) {
        throw new Error("Email is required");
      }

      const { data, error } = await supabase.auth.resend({
        type: "signup",
        email: email,
      });

      const { session, user } = data;

      if (error) {
        throw error;
      }
      dispatch(setAuthError(false));
      toast.success("OTP sent successfully");

      return true;
    } catch (e) {
      console.log("LOG: confirmOtp error:-", e);
      let error = _.get(e, "message", "Something went wrong");
      if (error === "Failed to fetch") {
        error = "Netwrok Error. Please check your internet connection";
      }
      dispatch(setAuthError(error));
      return false;
    }
  };
};

export const createCandidateDetails = ({
  firstName,
  middleName,
  lastName,
  gender,
}) => {
  return async (dispatch, getState, { client }) => {
    const response = await supabase.auth.getSession();
    const session = _.get(response, "data.session", {});

    if (!_.isEmpty(session)) {
      dispatch(setUserDetailsFromSupabasSession(session));
      const { data } = await client.mutate({
        mutation: CREATE_CANDIDATE,
        variables: {
          input: {
            firstName,
            middleName,
            lastName,
            gender,
          },
        },
      });
      return true;
    }

    return false;
  };
};

export const updateThridPartySignIn = () => {
  return async (dispatch, getState, { client }) => {
    const response = await supabase.auth.getSession();
    // console.log("LOG: response", response);
    const session = _.get(response, "data.session", {});

    if (!_.isEmpty(session)) {
      // dispatch(setUserDetailsFromSupabasSession(session));
      const { data } = await client.mutate({
        mutation: CREATE_CANDIDATE_OAUTH,
      });

      const newResponse = await supabase.auth.refreshSession();
      // const newResponse = await supabase.auth.getSession();
      // console.log("LOG: response", newResponse);
      const newSession = _.get(response, "data.session", {});

      dispatch(setUserDetailsFromSupabasSession(newSession));
      return true;
    }

    return false;
  };
};

export const setUsePlanInState = () => {
  return async (dispatch, getState, { client }) => {
    const state = getState();
    const userObj = _.get(state, "Auth.user", {});
    const isCandidate = _.get(userObj, "isCandidate", false);
    if (isCandidate) {
      try {
        const userDetailsData = await client.query({
          query: GET_CANDIDATE_DETAILS,
        });

        const currentPlan = _.get(
          userDetailsData,
          "data.candidate.extra_info.plan",
          "NO_PLAN"
        );
        dispatch(setPlan(currentPlan));
      } catch (e) {
        console.error("LOG: e", e);
      }
    }
    return false;
  };
};

export const setUserDetailsFromSupabasSession = session => {
  return async (dispatch, getState, { client }) => {
    const userObj = getUserObjectFromSession(session);
    const isCandidate = _.get(userObj, "isCandidate", false);

    if (isCandidate) {
      try {
        const userDetailsData = await client.query({
          query: GET_CANDIDATE_DETAILS,
        });

        const currentPlan = _.get(
          userDetailsData,
          "data.candidate.extra_info.plan",
          "NO_PLAN"
        );
        dispatch(setPlan(currentPlan));
      } catch (e) {
        console.error("LOG: e", e);
      }
    }
    dispatch(setUserDetails(userObj));
  };
};

export const forgotPasswordThunk = email => {
  return async dispatch => {
    dispatch(forgotPassword({ email }));
  };
};

export const resetPasswordThunk = () => {
  return async dispatch => {
    dispatch(resetPassword());
  };
};

export const resetAuth = () => {
  return async dispatch => {
    dispatch(reset());
  };
};

// Reducers
const REDUCER_HANDLERS = {
  [LOGOUT_USER]: (state, action) => {
    return update(state, {
      currentPlan: { $set: null },
      user: { $set: null },
      userLogout: { $set: true },
      loading: { $set: false },
    });
  },
  [FORGOT_PASSWORD]: (state, action) => {
    return update(state, {
      resetPasswordSuccess: { $set: action.payload },
      passwordReset: { $set: true },
      loading: { $set: false },
    });
  },

  [RESET]: (state, action) => {
    return {
      ...state,
      loading: false,
      error: false,
      userSignedUpEmail: "",
      passwordReset: false,
      resetPasswordSuccess: null,
      userLogout: true,
    };
  },
  [SET_AUTH_LOADING]: (state, action) => {
    return update(state, {
      loading: { $set: action.payload },
    });
  },
  [SET_AUTH_ERROR]: (state, action) => {
    return update(state, {
      error: { $set: action.payload },
    });
  },
  [SIGNUP_USER]: (state, action) => {
    return update(state, {
      userSignedUpEmail: { $set: action.payload },
      loading: { $set: false },
    });
  },
  [SET_USER]: (state, action) => {
    return update(state, {
      user: { $set: action.payload },
    });
  },
  [CLEAR_USER]: (state, action) => {
    return update(state, {
      user: { $set: {} },
    });
  },
  [SET_PLAN]: (state, action) => {
    return update(state, {
      currentPlan: { $set: action.payload },
    });
  },
  [RESET_PLAN]: (state, action) => {
    return update(state, {
      currentPlan: { $set: null },
    });
  },
};

let userObj = {};
let currentPlan = null;
try {
  const data = await supabase.auth.getSession();
  userObj = getUserObjectFromSession(_.get(data, "data.session", {}));
  const isCandidate = _.get(userObj, "isCandidate", false);

  if (isCandidate) {
    try {
      const userDetailsData = await graphClient.query({
        query: GET_CANDIDATE_DETAILS,
      });

      const currentPlanValue = _.get(
        userDetailsData,
        "data.candidate.extra_info.plan",
        ""
      );
      if (currentPlanValue) {
        currentPlan = currentPlanValue;
      }
    } catch (e) {
      console.error("LOG: e", e);
    }
  }
} catch (e) {
  console.error("LOG: e", e);
}

// Export Reducer
const initialState = {
  user: userObj,
  currentPlan: currentPlan,
  loading: false,
  error: false,
  userSignedUpEmail: "",

  passwordReset: false,
  resetPasswordSuccess: null,
  userLogout: false,
};

export default function myReducer(state = initialState, action) {
  const handler = REDUCER_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
