//@ts-nocheck
import update from "immutability-helper";

export const NAME = "COMMON";

// use common for toast msg & loading

export const SET_LOADING = `SET_LOADING ${NAME}`;
export const SET_TOAST_MSG = `SET_TOAST_MSG ${NAME}`;
export const CLEAR_TOAST_MSG = `CLEAR_TOAST_MSG ${NAME}`;

export const setLoading = data => {
  return {
    type: SET_LOADING,
    payload: data,
  };
};

export const setToastMsg = data => {
  return {
    type: SET_TOAST_MSG,
    payload: data,
  };
};

export const clearToastMsg = () => {
  return {
    type: CLEAR_TOAST_MSG,
  };
};

//THUNKS
export const showToastMsg =
  (msg, time = 3000) =>
  async dispatch => {
    dispatch(setToastMsg(msg));
    setTimeout(() => {
      dispatch(clearToastMsg());
    }, time);
  };

export const clearToast = () => async dispatch => {
  dispatch(clearToastMsg());
};

export const setLoadingState = state => async dispatch => {
  dispatch(setLoading(state));
};

export const toggleLoadingState = () => async (dispatch, getState) => {
  const { loading } = getState().common;
  dispatch(setLoading(!loading));
};

export const resetCommon = () => async dispatch => {
  dispatch(setLoading(false));
  dispatch(clearToastMsg());
};

// Reducer
const REDUCER_HANDLERS = {
  [SET_LOADING]: (state, action) => {
    return update(state, {
      loading: { $set: action.payload },
    });
  },
  [SET_TOAST_MSG]: (state, action) => {
    return update(state, {
      toastMsg: { $set: action.payload },
    });
  },
  [CLEAR_TOAST_MSG]: (state, action) => {
    return update(state, {
      toastMsg: { $set: null },
    });
  },
};

//intial state
const initialState = {
  loading: false,
  toastMsg: null,
};

export default function myReducer(state = initialState, action) {
  const handler = REDUCER_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
