import { gql } from "@apollo/client";

export const CREATE_CANDIDATE = gql`
  mutation updateUser($input: CandidateInput) {
    updateCandidate(input: $input) {
      id
    }
  }
`;

export const CREATE_CANDIDATE_OAUTH = gql`
  mutation createCandidate {
    createCandidate
  }
`;

export const ADD_TODO = gql`
  mutation addTodo($title: String!) {
    addTodo(title: $title) {
      id
      title
    }
  }
`;

export const ADD_JOB = gql`
  mutation addJob($input: JobInput!) {
    employee {
      addJob(input: $input) {
        id
        role
        companyName
        description
        location
        jobLink
        salary
        status
        appliedBy {
          id
          firstName
          lastName
          email
        }
        createdAt
        jobPostedAt
      }
    }
  }
`;

export const ADD_JOB_CANDIDATE = gql`
  mutation addJob($input: JobInput!) {
    candidate {
      addJob(input: $input) {
        id
        role
        companyName
        description
        location
        jobLink
        salary
        status
        appliedBy {
          id
          firstName
          lastName
          email
        }
        createdAt
        jobPostedAt
      }
    }
  }
`;

export const ADD_EMPLOYEE = gql`
  mutation addEmployee($input: EmployeeInput!) {
    admin {
      addEmployee(input: $input) {
        id
        email
      }
    }
  }
`;

export const UPDATE_JOB = gql`
  mutation updateJob($id: ID!, $input: JobInput!) {
    employee {
      updateJob(id: $id, input: $input) {
        id
        role
        companyName
        description
        location
        jobLink
        salary
        status
        createdAt
        jobPostedAt
      }
    }
  }
`;

export const DELETE_JOB = gql`
  mutation deleteJob($id: ID!) {
    employee {
      deleteJob(id: $id)
    }
  }
`;

export const CHANGE_JOB_STATUS_CANDIDATE = gql`
  mutation changeJobStatus($id: ID!, $status: String!) {
    candidate {
      changeJobStatus(id: $id, status: $status) {
        id
        role
        companyName
        description
        location
        jobLink
        salary
        status
        createdAt
        jobPostedAt
      }
    }
  }
`;

export const CHANGE_JOB_STATUS = gql`
  mutation changeJobStatus($id: ID!, $status: String!) {
    employee {
      changeJobStatus(id: $id, status: $status) {
        id
        role
        companyName
        description
        location
        jobLink
        salary
        status
        createdAt
        jobPostedAt
      }
    }
  }
`;

export const UPDATE_CANDIDATE_PROFILE = gql`
  mutation updateProfile(
    $profileInput: CandidateInput
    $preferencesInput: PreferncesInput
    $extraInfoInput: ExtraInfoInput
    $documentsInfoInput: DocumentsInfoInput
  ) {
    candidate {
      updateProfile(
        profileInput: $profileInput
        preferencesInput: $preferencesInput
        extraInfoInput: $extraInfoInput
        documentsInfoInput: $documentsInfoInput
      ) {
        id
        firstName
        middleName
        lastName
        email
        phoneNumber
        gender
        interested_job_roles
        extra_info {
          dob
          race
          disability_status
          address
          credits
          alternate_phone_number
        }
        preferences {
          experience_in_years
          current_salary_in_usd
          linkedin_profile
          visa_status
          is_visa_sponsorship_required
          job_type
          current_employer
          notice_period_in_months
          companies_to_avoid
          location_preferences
          relocation_preference
          available_from
          salary_expectation_in_usd
          travel_flexibility
          criminal_record
          veteran_status
          additional_info
          metadata
        }
        documents {
          resume_links
          cover_letter_links
        }
      }
    }
  }
`;

export const CANDIDATE_REDEEM_REFERRAL_CODE = gql`
  mutation redeemReferralCode($code: String!) {
    candidate {
      redeemReferralCode(code: $code)
    }
  }
`;

export const CANCEL_SUB = gql`
  mutation cancelSubscription {
    candidate {
      cancelSubscription
    }
  }
`;
