import { gql } from "@apollo/client";

export const GET_LOCATIONS = gql`
  query getLocations {
    locations {
      id
      name
      description
      photo
    }
  }
`;

export const GET_TODOS = gql`
  query getTodos {
    getTodos {
      id
      title
    }
  }
`;

export const GET_CANDIDATES = gql`
  query allCandidates {
    employee {
      allCandidates {
        id
        firstName
        middleName
        lastName
        email
        role
        phoneNumber
        gender
        interested_job_roles
      }
    }
  }
`;

export const GET_EMPLOYEES = gql`
  query allCandidates {
    admin {
      allEmployees {
        id
        firstName
        middleName
        lastName
        email
        metrics {
          applied
          suggested
        }
      }
    }
  }
`;

export const GET_CANDIDATES_PAGINATED = gql`
  query candidates($limit: Int, $offset: Int) {
    employee {
      candidates(limit: $limit, offset: $offset) {
        id
        firstName
        middleName
        lastName
        email
        role
        phoneNumber
        interested_job_roles
      }
    }
  }
`;

export const CREATE_CHECKOUT_SESSION = gql`
  query createCheckoutSession($productId: String) {
    createCheckoutSession(productId: $productId) {
      url
    }
  }
`;

export const GET_JOBS = gql`
  query getJobs($status: JobStatus, $appliedFor: ID) {
    employee {
      getJobs(status: $status, appliedFor: $appliedFor) {
        id
        role
        companyName
        description
        location
        jobLink
        salary
        status
        appliedBy {
          id
          firstName
          lastName
          email
        }
        appliedFor {
          id
          firstName
          middleName
          lastName
          gender
        }
        createdAt
        jobPostedAt
      }
    }
  }
`;

export const GET_JOB_BY_ID = gql`
  query getJob($id: ID!) {
    employee {
      getJob(id: $id) {
        id
        role
        companyName
        description
        location
        jobLink
        salary
        status
        appliedBy {
          id
          firstName
          lastName
          email
        }
        appliedFor {
          id
          firstName
          middleName
          lastName
          gender
        }
        createdAt
        jobPostedAt
      }
    }
  }
`;

export const GET_CANDIDATE_JOBS = gql`
  query getJobs($status: JobStatus) {
    candidateQueries {
      getJobs(status: $status) {
        id
        role
        companyName
        description
        location
        jobLink
        salary
        status
        appliedBy {
          id
          firstName
          lastName
          email
        }
        appliedFor {
          id
          firstName
          middleName
          lastName
          gender
        }
        createdAt
        jobPostedAt
      }
    }
  }
`;

export const GET_CANDIDATE_JOB_BY_ID = gql`
  query getJob($id: ID!) {
    candidateQueries {
      getJob(id: $id) {
        id
        role
        companyName
        description
        location
        jobLink
        salary
        status
        appliedBy {
          id
          firstName
          lastName
          email
        }
        appliedFor {
          id
          firstName
          middleName
          lastName
          gender
        }
        createdAt
        jobPostedAt
      }
    }
  }
`;

export const GET_JOBS_PAGINATED = gql`
  query getJobsWithPagination($limit: Int, $offset: Int) {
    employee {
      getJobsWithPagination(limit: $limit, offset: $offset) {
        id
        role
        companyName
        description
        location
        jobLink
        salary
        status
        appliedBy
        createdAt
        jobPostedAt
      }
    }
  }
`;

export const GET_CANDIDATE = gql`
  query getCandidate($id: ID!) {
    employee {
      candidate(id: $id) {
        id
        firstName
        middleName
        lastName
        email
        phoneNumber
        gender
        interested_job_roles
        extra_info {
          dob
          race
          disability_status
          address
          credits
          alternate_phone_number
        }
        preferences {
          experience_in_years
          current_salary_in_usd
          linkedin_profile
          visa_status
          is_visa_sponsorship_required
          job_type
          current_employer
          notice_period_in_months
          companies_to_avoid
          location_preferences
          relocation_preference
          available_from
          salary_expectation_in_usd
          travel_flexibility
          criminal_record
          veteran_status
          additional_info
          metadata
        }
        documents {
          cover_letter_links
          resume_links
        }
      }
    }
  }
`;

export const GET_CANDIDATE_PROFILE_DETAILS = gql`
  query getCandidate {
    candidate {
      id
      firstName
      middleName
      lastName
      email
      phoneNumber
      gender
      interested_job_roles
      extra_info {
        dob
        race
        disability_status
        address
        credits
        alternate_phone_number
      }
      preferences {
        experience_in_years
        current_salary_in_usd
        linkedin_profile
        visa_status
        is_visa_sponsorship_required
        job_type
        current_employer
        notice_period_in_months
        companies_to_avoid
        location_preferences
        relocation_preference
        available_from
        salary_expectation_in_usd
        travel_flexibility
        criminal_record
        veteran_status
        additional_info
        metadata
      }
      documents {
        cover_letter_links
        resume_links
      }
    }
  }
`;

export const CREATE_CHECKOUT_SESSION_2 = gql`
  query createCheckoutSession($priceId: String) {
    createCheckoutSession(priceId: $priceId) {
      url
    }
  }
`;

export const GET_CANDIDATE_DETAILS = gql`
  query getCandidateDetails {
    candidate {
      id
      firstName
      lastName
      email
      extra_info {
        plan
        credits
      }
    }
  }
`;

export const GET_CANDIDATE_METRICS = gql`
  query getCandMetrics {
    candidateQueries {
      candidateMetrics {
        credits
        jobs_applied
      }
    }
  }
`;

export const GET_EMPLOYEE_METRICS = gql`
  query getEmployeeMetrics {
    employee {
      employeeMetrics {
        applied
        suggested
        rejected
      }
    }
  }
`;

export const CANDIDATE_GET_REFFERAL_CODE = gql`
  query getCandidateReferralCode {
    candidateQueries {
      getReferralCode
    }
  }
`;

export const CANDIDATE_GET_REFFERED_BY_CANIDATE = gql`
  query getRefferedByCandidateDetails {
    candidateQueries {
      getRefferedByCandidateDetails {
        firstName
        middleName
        lastName
        email
      }
    }
  }
`;

export const CANDIDATE_GET_REFFERED_CANIDATES = gql`
  query getRefferedCandidates {
    candidateQueries {
      getRefferedCandidates {
        firstName
        middleName
        lastName
        email
      }
    }
  }
`;
