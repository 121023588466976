import config from "config";
import _ from "lodash";
const getJwt = () => {
  try {
    const jwtKey = `sb-${config.SUPABASE_APP_ID}-auth-token`;
    const userObject = localStorage.getItem(jwtKey) || "";

    const jwt = _.get(JSON.parse(userObject), "access_token", "");

    return jwt;
  } catch (e) {
    console.log(e);
    return "";
  }
};

export default getJwt;
